import React, { Component } from 'react';

class ServicesText extends Component {

    render() {
        return (
            <div>
            <div className="content-subtitle bold-text top-title">Our Services</div>
            <p className="long-content">At Perpetuum IT, we offer a comprehensive suite of services across software development, information security, and IT consulting sectors. Share your challenges with us, and we'll match you with an expert who's best suited to meet your needs.</p>
        
            <div className="content-subtitle bold-text">Service Offerings</div>
            <p className="long-content">Our professionals are equipped to handle a wide array of services to support and enhance your IT infrastructure and projects:</p>
            
            <p className="long-content">Cloud Computing Infrastructure Management:</p>
                <ul className="service-list">
                    <li>AWS Service Configuration and Management</li>
                    <li>Azure Cloud Solutions</li>
                    <li>Google Cloud Platform Services</li>
                    <li>Digital Ocean Infrastructure Management</li>
                </ul>
            <p className="long-content">Information Security Consulting:</p>
                <ul className="service-list">
                    <li>Website Security Testing and Optimization</li>
                    <li>Server Vulnerability Scanning and Remediation</li>
                    <li>Installation and Maintenance of Detection and Prevention Systems</li>
                    <li>Complete Information Security Audits</li>
                </ul>
            <p className="long-content">IT Consulting:</p>
                <ul className="service-list">
                    <li>System Architecture Design</li>
                    <li>Infrastructure Setup and Testing</li>
                    <li>DNS and Domain Management</li>
                    <li>Technical Documentation Creation and Maintenance</li>
                    <li>Infrastructure Scalability and Performance Tuning</li>
                    <li>Database Performance Optimization</li>
                    <li>System Administration and Automation</li>
                </ul>
            <p className="long-content">Software Development:</p>
                <ul className="service-list">
                    <li>Custom Web Development</li>
                    <li>Android App Development</li>
                    <li>iOS App Development</li>
                    <li>Streaming Solutions with Wowza Streaming Engine</li>
                    <li>Real-time Chat Server Development with Socket.io</li>
                </ul>
        
            <p className="long-content">This list is not exhaustive. Reach out to us with your specific requirements, and we will tailor our services to your project's unique demands.</p>
            <p className="long-content">For those looking to engage our services directly through Upwork, please visit our agency profile. You can easily make an order and start your project with us through this platform.</p>
            <p className="long-content"><a className="business-link" href="https://www.upwork.com/agencies/perpetuumit/">Visit our Upwork Profile</a></p>
        </div>
        
        )
    }

}

export default ServicesText;