import React, { Component } from 'react';

class CareersText extends Component {

    render() {
        return (
            <div>
                <div className="content-subtitle bold-text top-title">Join Our Network</div>
                <p className="long-content">Perpetuum IT is where individual expertise meets collective ambition. We provide a platform for technology specialists to lend their skills to prestigious companies and impactful projects.</p>
                <p className="long-content">Our network is vast, and the opportunities are plentiful, designed to suit your professional prowess and preferences.</p>
                
                <div className="content-subtitle bold-text">Your Opportunity</div>
                <p className="long-content">We respect the uniqueness of your career journey, offering the flexibility to engage with projects that fit your skill set and professional vision.</p>
                <p className="long-content">Your growth is our priority, and we facilitate it by connecting you with roles that challenge and excite you.</p>
                
                <div className="content-subtitle bold-text">Our Commitment</div>
                <p className="long-content">Perpetuum IT commits to your professional narrative. Our approach goes beyond filling positions—it’s about creating matches that resonate with your ambitions and lifestyle.</p>
                <p className="long-content">We provide the autonomy to influence your work environment, with the freedom to choose where and how you make your mark in the tech world.</p>
                
                <div className="content-subtitle bold-text">Forge Your Path</div>
                <p className="long-content">Embark on a journey that leads to professional fulfillment. Perpetuum IT doesn’t just outsource talent; we advocate for it, ensuring each project you undertake is a step towards greater achievements.</p>
                <p className="long-content">With us, your capabilities find the right platform to shine, driving technology forward one successful assignment at a time.</p>
      
                <div className="content-subtitle bold-text">Expand Your Horizons with Us</div>
                <p className="long-content">For independent tech professionals seeking exciting project opportunities, we welcome you to join our network on Upwork. This platform allows us to connect seamlessly, offering you a wide range of projects that match your expertise and preferences.</p>
                <p className="long-content"><a className="business-link" href="https://www.upwork.com/agencies/perpetuumit/">Join us on Upwork</a> and be part of a community that values your skills and offers the flexibility to choose projects that inspire you.</p>
            </div>
        
        )
    }

}

export default CareersText;