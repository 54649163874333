import React, { Component } from 'react';

class HomepageText extends Component {

    render() {
        return (
            <div>
                <div className="content-subtitle bold-text top-title">Who We Are</div>
                <p className="long-content">Perpetuum IT stands as a gateway for technology professionals to join leading companies and contribute to groundbreaking projects. We are an outsourcing powerhouse connecting skilled individuals with the right opportunities.</p>
                <p className="long-content">Our role is pivotal in facilitating a match between the aspirations of proficient tech experts and the diverse needs of businesses across industries.</p>
                
                <div className="content-subtitle bold-text">Our Mission</div>
                <p className="long-content">Our mission is clear: to navigate the vast talent pool of the tech world and supply our clients with professionals who turn complex problems into elegant solutions.</p>
                <p className="long-content">Every partnership we curate is tailored, ensuring a harmonious fit between developer expertise and project requirements.</p>
                
                <div className="content-subtitle bold-text">Our Vision</div>
                <p className="long-content">Perpetuum IT envisions a seamless conduit of talent and opportunity. We aspire to build bridges that allow for the effortless exchange of knowledge and innovation.</p>
                <p className="long-content">Our vision is to shape the future of technology outsourcing with integrity, precision, and reliability.</p>
            </div>

        )
    }

}

export default HomepageText;