import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from 'webpages/Homepage.js';
import ContactUs from 'webpages/Contact_Us.js';
import Careers from 'webpages/Careers.js';
import Services from 'webpages/Services.js';
import PrivacyPolicy from 'webpages/Privacy_Policy.js';

import Menu from 'components/widgets/Menu_Drawer.js';
import CookieConsent from 'components/dialogs/Cookie_Consent.js';


const Main = () => {

    return (
        <>
            <Router>
            <CookieConsent />
            <Menu/>
                <Routes>
                    <Route exact path="/" element={<Homepage/>}></Route>
                    <Route exact path="/contact-us" element={<ContactUs/>}></Route>
                    <Route exact path="/careers" element={<Careers/>}></Route>
                    <Route exact path="/services" element={<Services/>}></Route>
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
                </Routes>
            </Router>
        </>
    );
};

export default Main;