import React from 'react';

class Breadcrumb extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.path = props.path;
    }

    render() {
        return (
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "name": "${this.title}",
                "itemListElement": [
                {
                    "@type": "ListItem",
                "position": 1,
                "name": "Perpetuum IT",
                "item": "https://perpetuumit.dev"
                },
                {
                    "@type": "ListItem",
                "position": 2,
                "name": "${this.title}",
                "item": "https://perpetuumit.dev${this.path}"
                }
                ]
                }`}
            </script>
        )
    }
}

export default Breadcrumb;