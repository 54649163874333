import Main from 'Main.js';

function App() {

    return (
      <div id='rootChild'>
            <Main />
        </div>
    );
}

export default App;