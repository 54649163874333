import React from 'react';


class Consent extends React.Component {

    static addScripts() {

        const googleAnalyticsStaticScript = document.createElement("script");
        googleAnalyticsStaticScript.src = "https://www.googletagmanager.com/gtag/js?id=G-0YJYH84L5K";
        googleAnalyticsStaticScript.async = "async";

        const googleAnalyticsDynamicScript = document.createElement("script");
        googleAnalyticsDynamicScript.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-0YJYH84L5K');";
       
        document.head.appendChild(googleAnalyticsStaticScript);
        document.head.appendChild(googleAnalyticsDynamicScript);
    }


    static approveThirdParty() {
        localStorage.setItem('thirdPartyAccepted', 'true');
        Consent.addThirdParty();
    }

    static declineThirdParty() {
        localStorage.setItem('thirdPartyAccepted', 'false');
    }

    static isBot() {
        const userAgent = navigator.userAgent.toLowerCase();
        return (
            userAgent.includes('googlebot') ||
            userAgent.includes('google-inspectiontool') ||
            userAgent.includes('woobot') ||
            userAgent.includes('woorank') ||
            userAgent.includes('baiduspider') ||
            userAgent.includes('chrome-lighthouse') ||
            userAgent.includes('bingbot') ||
            userAgent.includes('adsbot-google') ||
            userAgent.includes('slurp') ||
            userAgent.includes('duckduckbot') ||
            userAgent.includes('yandexbot') ||
            userAgent.includes('sogou') ||
            userAgent.includes('exabot') ||
            userAgent.includes('facebot') ||
            userAgent.includes('facebookexternalhit') ||
            userAgent.includes('ia_archiver') ||
            userAgent.includes('sp_auditbot')
        );
    }
    static addThirdParty() {

        const thirdPartyAcceptedValue = localStorage.getItem('thirdPartyAccepted');
        if (Consent.isBot() || thirdPartyAcceptedValue === 'true') {
            Consent.addScripts();
        }
    }

}

export default Consent;