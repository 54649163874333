import React, { useEffect } from 'react';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Organization from "components/seo/Organization.js";
import Breadcrumb from "components/seo/Breadcrumb.js";
import HomepageText from "components/text/Homepage_Text.js";
import MetaTags from "components/seo/Meta_Tags.js";
import Footer from "components/sections/Footer.js";
import Consent from 'utils/Consent.js';

const Homepage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        Consent.addThirdParty();
    }, []); 

    return (
        <>
            <MetaTags
                title="Innovative IT Solutions - PerpetuumIT.dev"
                description="Lead with Perpetuum IT's advanced IT outsourcing and consultancy. Tailored solutions for your tech success."
                url="/"
            />
            <Organization />
            <Breadcrumb
                title="Innovative IT Solutions - PerpetuumIT.dev"
                path="/"
            />
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="page-title">About Us</h1>
                            </div>
                            <div className="page-container">
                                <HomepageText />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Homepage;