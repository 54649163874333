import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class MetaTags extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.description = props.description;
        this.url = props.url;
    }

    render() {
        if (this.description !== '')
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name="description" content={this.description} />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:site" content="Perpetuum IT" />
                        <meta name="twitter:title" content={this.title} />
                        <meta name="twitter:description" content={this.description} />
                        <meta name="twitter:image" content={`https://perpetuumit.dev/images/home-icon.png`} />
                        <meta property="og:title" content={this.title} />
                        <meta property="og:description" content={this.description} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`https://perpetuumit.dev${this.url}`} />
                        <meta property="og:image" content={`https://perpetuumit.dev/images/home-icon.png`} />
                        <link rel="canonical" href={`https://perpetuumit.dev${this.url}`} />
                        <title>{this.title}</title>
                    </Helmet>
                </HelmetProvider>
            );
        return (
            <HelmetProvider>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                    <title>{this.title}</title>
                </Helmet>
            </HelmetProvider>
        );

    }

}

export default MetaTags;