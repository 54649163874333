import React, { useState, useEffect } from 'react';
import Consent from 'utils/Consent.js';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('thirdPartyAccepted');
        if (!Consent.isBot() && consentGiven === null) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        Consent.approveThirdParty();
        setIsVisible(false);
    };

    const handleDecline = () => {
        Consent.declineThirdParty();
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-dialog-container">
        <div className="cookie-consent-dialog">
            <p className="long-content">We use cookies to improve your experience. By continuing to use our site, you accept our use of cookies.</p>
            <button className="accept-cookies" onClick={handleAccept}>Accept</button>
            <button className="decline-cookies" onClick={handleDecline}>Decline</button>
        </div>
        </div>
    );
};

export default CookieConsent;
