import React from 'react';
import { Link } from 'react-router-dom';

class ContentPageLogo extends React.Component {

    render() {
        return <>
            <Link to="/">
                <img src="/images/header-icon.png" width="184" height="49" alt="Home" className="content-page-logo" />
            </Link>
        </>;

    }

}

export default ContentPageLogo;