import React, { useEffect } from 'react';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Organization from "components/seo/Organization.js";
import Breadcrumb from "components/seo/Breadcrumb.js";
import PrivacyPolicyText from "components/text/Privacy_Policy_Text.js";
import MetaTags from "components/seo/Meta_Tags.js";
import Footer from "components/sections/Footer.js";
import Consent from 'utils/Consent.js';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        Consent.addThirdParty();
    }, []); 

    return (
        <>
            <MetaTags
                title=" Privacy Policy - PerpetuumIT.dev"
                description="Our Privacy Policy upholds your data protection rights. Trust Perpetuum IT with your information security."
                url="/privacy-policy"
            />
            <Organization />
            <Breadcrumb
                title=" Privacy Policy - PerpetuumIT.dev"
                path="/privacy-policy"
            />
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="page-title">Privacy Policy</h1>
                            </div>
                            <div className="page-container">
                                <PrivacyPolicyText />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PrivacyPolicy;