import React from 'react';
import ContentPageLogo from "components/images/Content_Page_Logo.js";

class ContentPageHeader extends React.Component {

    render() {
        
        return <header className="content-page-header">
            <ContentPageLogo />
            <a href="https://www.upwork.com/agencies/perpetuumit/" className="make-order-button">Order our services</a>
        </header>;

    }

}

export default ContentPageHeader;