import React, { Component } from 'react';
import { Link } from 'react-router-dom';

let thisComponent;

const menuSwipeSensitivity = 75;
const menuToggleTimeout = 50;


let touchStart;
let touchEnd;

let lastMenuUpdateTime;

class Menu extends Component {
    constructor(props) {
        super(props);
        thisComponent = this;
        thisComponent.showMenu = false;
        lastMenuUpdateTime = new Date().getTime();
    }


    toggleMenu() {
        if (new Date().getTime() - lastMenuUpdateTime < menuToggleTimeout) return;
        let oldClassName = document.getElementById('menu-drawer').className;
        let newClassName = oldClassName === 'menu-drawer' ? 'menu-drawer open' : 'menu-drawer';
        document.getElementById('menu-drawer').className = newClassName;
        if (newClassName === 'menu-drawer open') {
            document.body.style.overflow = 'hidden';
            document.getElementById('menu-drawer').style = '';
            document.getElementById('menu-options-container').style = '';
        } else if (newClassName === 'menu-drawer') {
            document.body.style.overflow = 'visible';
        }
        thisComponent.showMenu = !thisComponent.showMenu;
        if (thisComponent.showMenu) thisComponent.trackSwipping();
        else {
            window.removeEventListener('touchstart', thisComponent.handleStart, false);
            window.removeEventListener('touchend', thisComponent.handleEnd, false);
            window.removeEventListener('touchmove', thisComponent.handleMove, false);
        }
        lastMenuUpdateTime = new Date().getTime();
    }

    render() {

        return (
            <div id="menu-div">
                <img
                    src="/images/menu-bar.svg"
                    onClick={this.toggleMenu}
                    width="30"
                    height="auto"
                    alt="Open menu"
                    className="menu-bar"
                    id="menu-bar"
                />
                <div className="menu-drawer" id="menu-drawer">
                    <div className="menu-container menu-top">
                        <Link to="/">
                            <div id="logo-menu-div">
                                <img
                                    src="/images/home-icon.png"
                                    width="49"
                                    height="49"
                                    onClick={this.toggleMenu}
                                    alt="Home"
                                    className="home-image"
                                />
                            </div>
                        </Link>
                        <div id="exit-menu-div">
                            <img
                                src="images/close.svg"
                                onClick={this.toggleMenu}
                                width="29"
                                height="29"
                                alt="Close menu"
                                className="close-menu"
                            />
                        </div>
                    </div>
                    <div className="menu-container menu-options-container" id="menu-options-container">
                <Link
                    onClick={this.toggleMenu}
                    to="/"
                    className="menu-option-button"
                >
                Perpetuum IT
                </Link>
                <Link
                    onClick={this.toggleMenu}
                    to="/careers"
                    className="menu-option-button"
                >
                Careers
                </Link>
                <Link
                    onClick={this.toggleMenu}
                    to="/services"
                    className="menu-option-button"
                >
                Services
                </Link>
                <Link
                    onClick={this.toggleMenu}
                    to="/contact-us"
                    className="menu-option-button"
                >
                Contact Us
                </Link>
                <Link
                    onClick={this.toggleMenu}
                    to="/privacy-policy"
                    className="menu-option-button no-border"
                >
                Privacy Policy
                </Link>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        thisComponent.newsFetcher?.removeActions();
        thisComponent.newsFetcher = null;
        window.removeEventListener('touchstart', this.handleStart, false);
        window.removeEventListener('touchend', this.handleEnd, false);
        window.removeEventListener('touchmove', this.handleMove, false);
    }

    trackSwipping() {
        window.addEventListener('touchstart', this.handleStart, false);
        window.addEventListener('touchend', this.handleEnd, false);
        window.addEventListener('touchmove', this.handleMove, false);
    }

    handleStart(e) {
        touchStart = e.targetTouches[0].clientX;
    }

    handleMove(e) {
        touchEnd = e.targetTouches[0].clientX;
    }

    handleEnd() {
        if (touchStart - touchEnd > menuSwipeSensitivity) {
            thisComponent.toggleMenu();
        }
    }
}

export default Menu;
