import React from 'react';
import { Link } from 'react-router-dom';


class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.lightMode = props.lightMode || false;
    }

    render() {


        return (
            <footer>
                <div className='footer-icons'>
                    <a href="https://www.linkedin.com/company/perpetuum-it">
                        <img
                            src="/images/linkedin.png"
                            alt="LinkedIn"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a href="https://wa.me/perpetuum_it">
                        <img
                            src="/images/whatsapp.png"
                            alt="WhatSApp"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a href="https://t.me/perpetuum_it">
                        <img
                            src="/images/telegram.png"
                            alt="Telegram"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a href="https://www.upwork.com/agencies/perpetuumit/">
                        <img
                            src="/images/upwork.png"
                            alt="Upwork"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                </div>
                {
                    <div className="legal-links-div">
                        <Link
                            to="/privacy-policy"
                            className="footer-link"
                            >
                                Privacy Policy
                        </Link>
                    </div>
                }
                <div className="flex gap-10 justify-center items-center">
                    <div className="copyright-div margin-0">&copy; Perpetuum IT</div>
                    <div className="rights-reserved margin-0">All rights reserved</div>
                </div>
            </footer>
        );
    }

}

export default Footer;